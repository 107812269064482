// Types
import type { ImageProps } from '@/types/components/atoms/image'

const ImageAtom = (props: ImageProps) => {
	// Props
	const { src, alt, width, height } = props

	return (
		<picture>
			<img src={src} alt={alt} width={width} height={height} />
		</picture>
	)
}

export default ImageAtom
